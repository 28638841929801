body {
    background-color: #ffffff;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.card {
    background: #fbfbfb;
    margin: 2rem;
    border-radius: 1rem;
    box-shadow: 3px 3px 6px lightgrey;
    transition: .5s;

}

.card:hover {
    box-shadow: 0px 0px 10px 0px;
}

.card.expanded {
    box-shadow: 0px 0px 50px 5px;
}

.card.simple {
    box-shadow: none;
}

img {
    pointer-events: none;
}

.react-share__ShareButton {
    display: block !important;
}

.parallax-layer-clicked {
    display: none;
}

.game-over {
    position: absolute;
    top: 25%;
    left: 25%;
    text-align: center;
}